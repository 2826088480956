import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom
import "./FlexboxComponent.css"; // Updated CSS file import
import {
  IoArrowBackCircleOutline,
  IoArrowForwardCircleOutline,
} from "react-icons/io5";
import { Leaf } from "lucide-react"; // Replace with the correct import for the Leaf icon

const FlexboxComponent = ({ title, questions, nextPath, prevPath }) => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [yesNoAnswer, setYesNoAnswer] = useState(null);
  const [answers, setAnswers] = useState({}); // State to hold the answers
  const navigate = useNavigate(); // Initialize navigate from react-router

  const handleNavigate = (path) => {
    navigate(path); // Use navigate for internal routing
  };

  const nextQuestion = () => {
    if (currentQuestion < questions.length - 1) {
      // If not the last question, move to the next
      setCurrentQuestion(currentQuestion + 1);
    } else {
      // If on the last question, navigate to the next page
      handleNavigate(nextPath); // Use the passed nextPath
    }
  };

  const prevQuestion = () => {
    if (currentQuestion > 0) {
      // If not the first question, move to the previous
      setCurrentQuestion(currentQuestion - 1);
    } else {
      // If on the first question, navigate to the previous page
      handleNavigate(prevPath); // Use the passed prevPath
    }
  };

  const handleYesNoClick = (answer) => {
    setYesNoAnswer(answer);
  };

  const handleAnswer = (value) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questions[currentQuestion].id]: value,
    }));
  };

  const renderQuestion = () => {
    const currentQuestionData = questions[currentQuestion];
    switch (currentQuestionData.type) {
      case "text":
      case "number":
        return (
          <div className="flexbox-form-group">
            <label htmlFor={currentQuestionData.id}>
              {currentQuestionData.text}
            </label>
            <input
              id={currentQuestionData.id}
              type={currentQuestionData.type}
              value={answers[currentQuestionData.id] || ""}
              onChange={(e) => handleAnswer(e.target.value)}
              className="flexbox-form-control"
            />
          </div>
        );
      case "radio":
        return (
          <div className="flexbox-form-group">
            <p>{currentQuestionData.text}</p>
            {currentQuestionData.options.map((option) => (
              <div key={option} className="flexbox-radio-option">
                <input
                  type="radio"
                  id={`${currentQuestionData.id}-${option}`}
                  name={currentQuestionData.id}
                  value={option}
                  checked={answers[currentQuestionData.id] === option}
                  onChange={() => handleAnswer(option)}
                />
                <label htmlFor={`${currentQuestionData.id}-${option}`}>
                  {option}
                </label>
              </div>
            ))}
          </div>
        );
      case "select":
        return (
          <div className="flexbox-form-group">
            <label htmlFor={currentQuestionData.id}>
              {currentQuestionData.text}
            </label>
            <select
              id={currentQuestionData.id}
              value={answers[currentQuestionData.id] || ""}
              onChange={(e) => handleAnswer(e.target.value)}
              className="flexbox-form-control"
            >
              <option value="">Select an option</option>
              {currentQuestionData.options.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        );
      case "range":
        return (
          <div className="flexbox-form-group">
            <label htmlFor={currentQuestionData.id}>
              {currentQuestionData.text}
            </label>
            <input
              id={currentQuestionData.id}
              type="range"
              min={currentQuestionData.min}
              max={currentQuestionData.max}
              step={currentQuestionData.step}
              value={answers[currentQuestionData.id] || currentQuestionData.min}
              onChange={(e) => handleAnswer(e.target.value)}
              className="flexbox-form-control"
            />
            <span>{answers[currentQuestionData.id]}</span>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      {/* Header section */}
      <header className="flexbox-header">
        <div className="flexbox-logo" onClick={() => handleNavigate("/")}>
          <Leaf className="flexbox-logo-icon" />
          <span className="flexbox-logo-text">kyc.earth</span>
        </div>
        <nav className="flexbox-nav">
          <div
            className="flexbox-nav-link1"
            onClick={() => handleNavigate("#about")}
          >
            About
          </div>
          <div
            className="flexbox-nav-link2"
            onClick={() => handleNavigate("#contact")}
          >
            Contact
          </div>
        </nav>
      </header>

      {/* Flexbox Component */}
      <div className="flexbox-container">
        <h1 className="flexbox-title">{title}</h1>

        <div className="flexbox-question-container">
          {questions.map((q, index) => (
            <div
              key={index}
              className={`flexbox-question-box ${
                currentQuestion === index ? "flexbox-active" : ""
              } ${
                currentQuestion > index
                  ? "flexbox-slide-left"
                  : currentQuestion < index
                  ? "flexbox-slide-right"
                  : ""
              }`}
            >
              <h2 className="flexbox-question-title">{q.question}</h2>
              <div className="flexbox-input-container">{renderQuestion()}</div>
            </div>
          ))}
        </div>

        <div className="flexbox-navigation-buttons">
          <IoArrowBackCircleOutline
            className="button1"
            onClick={prevQuestion}
            disabled={currentQuestion === 0}
          />
          <IoArrowForwardCircleOutline
            className="button2"
            onClick={nextQuestion}
            disabled={currentQuestion === questions.length - 1}
          />
        </div>
      </div>
    </div>
  );
};

export default FlexboxComponent;
