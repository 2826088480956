import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import React, { useState, useEffect } from "react";
import Flex from "./components/Flex";
import House from "./components/House";
import Transport from "./components/Transportation";
import Waste from "./components/Waste";
import HomePage from "./components/HomePage";
import Diet from "./components/Diet";
import Goods from "./components/Goods";
import Water from "./components/Water";
function App() {
  const [loading, setLoading] = useState(true);
  const [houseData, setHouseData] = useState({});
  const [transportData, setTransportData] = useState({});
  const [wasteData, setWasteData] = useState({});
  const [dietData, setDietData] = useState({});
  const [goodsData, setGoodsData] = useState({});
  const [waterData, setWaterData] = useState({});
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="Home-Page" />} />
        <Route path="/Home-Page" element={<HomePage />} />
        <Route path="/House" element={<House setHouseData={setHouseData} />} />
        <Route
          path="/Transport"
          element={<Transport setTransportData={setTransportData} />}
        />
        <Route path="/Waste" element={<Waste setWasteData={setWasteData} />} />
        <Route path="/Diet" element={<Diet setDietData={setDietData} />} />
        <Route path="/Goods" element={<Goods setGoodsData={setGoodsData} />} />
        <Route path="/Water" element={<Water setWaterData={setWaterData} />} />
      </Routes>
    </Router>
  );
}

export default App;
// import React from "react";
// import Flex from "./components/Flex";
// import House from "./components/House";
// import Survey from "./components/Survey";
// import { Hourglass } from "lucide-react";
// const App = () => {
//   return (
//     <div>
//       <Flex />
//       {/* <House /> */}
//       {/* <House /> */}
//       {/* <Survey /> */}
//     </div>
//   );
// };

// export default App;
