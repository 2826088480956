import React from "react";
import FlexboxComponent from "./Flex";
import { useNavigate } from "react-router-dom";
import { IoHomeOutline } from "react-icons/io5";
import "./House.css";
const SurveyPage = () => {
  const navigate = useNavigate(); // Initialize navigate

  const handlePrevious = () => {
    navigate("/Home-Page"); // Now you can use navigate properly
  };

  const questionsData = [
    {
      id: "household-size",
      question: "How many people live in your household?",
      type: "range",
      min: 1,
      max: 10,
      placeholder: "No of people",
    },
    {
      id: "electricity-usage",
      question: "What is your average monthly electricity usage (in units)?",
      type: "number",
      placeholder: "(in units)",
    },
    {
      id: "renewable-energy",
      question: "Do you use any renewable energy sources like solar panels?",
      type: "radio",
      options: ["Yes", "No"],
    },
    {
      id: "heating-system",
      question: "What type of heating system do you use?",
      type: "select",
      options: ["Natural Gas", "Electric", "Wood", "Oil", "Propane", "None"],
    },
  ];

  return (
    <div>
      <IoHomeOutline
        className="home-btn"
        onClick={handlePrevious}
      ></IoHomeOutline>
      <FlexboxComponent
        title="Household Information"
        questions={questionsData}
        nextPath="/Transport" // This is the dynamic path you're passing
        prevPath="/Home-Page"
      />
    </div>
  );
};

export default SurveyPage;
