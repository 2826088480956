import React from "react";
import FlexboxComponent from "./Flex";
import { IoHomeOutline } from "react-icons/io5";
import useFormNavigation from "./Navigation"; // Import the custom hook
import "./Water.css";

const WaterPage = () => {
  // Questions data for the Water section
  const questionsData = [
    {
      id: "drinking-water",
      question: "How many gallons of drinking water do you consume daily?",
      type: "number",
      placeholder: "(gallons per day)",
    },
    {
      id: "showering-water",
      question:
        "How many gallons of water do you use for showering and bathing each day?",
      type: "number",
      placeholder: "(gallons per day)",
    },
    {
      id: "cooking-cleaning-water",
      question:
        "What is your average daily water usage for cooking and cleaning (in gallons)?",
      type: "number",
      placeholder: "(gallons per day)",
    },
    {
      id: "outdoor-water",
      question:
        "How much water do you use for outdoor purposes (e.g., gardening, lawn care) annually (in gallons)?",
      type: "number",
      placeholder: "(gallons per year)",
    },
    {
      id: "water-saving-devices",
      question:
        "Do you have any water-saving devices installed in your home (e.g., low-flow faucets, dual-flush toilets)? If so, how much water do you estimate they save annually?",
      type: "radio",
      options: ["Yes", "No"],
    },
  ];

  // Use the custom hook for navigation
  const { currentQuestion, nextQuestion, prevQuestion } = useFormNavigation(
    questionsData,
    "/summary", // Next page route after finishing the questions
    "/Goods" // Previous page route when going back
  );

  // Optional: Home button handler to navigate to the home page
  const handleHome = () => {
    prevQuestion(); // Navigate back using the previous question logic
  };

  return (
    <div>
      <IoHomeOutline className="water-home-btn" onClick={handleHome} />
      <FlexboxComponent
        title="Water Consumption Information"
        questions={questionsData}
        nextPath="/summary"
        prevPath="/Goods"
      />
    </div>
  );
};

export default WaterPage;
