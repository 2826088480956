import { useState } from "react";
import { useNavigate } from "react-router-dom"; // Assuming you're using react-router

const useFormNavigation = (questions, nextPage, prevPage) => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const navigate = useNavigate();

  const nextQuestion = () => {
    if (currentQuestion < questions.length - 1) {
      // If not the last question, move to the next
      setCurrentQuestion(currentQuestion + 1);
    } else {
      // If on the last question, navigate to the next page
      navigate(nextPage); // Navigate to the next page
    }
  };

  const prevQuestion = () => {
    if (currentQuestion > 0) {
      // If not the first question, move to the previous
      setCurrentQuestion(currentQuestion - 1);
    } else {
      // If on the first question, navigate to the previous page
      navigate(prevPage); // Navigate to the previous page
    }
  };

  return {
    currentQuestion,
    nextQuestion,
    prevQuestion,
  };
};

export default useFormNavigation;
