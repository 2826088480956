import React from "react";
import FlexboxComponent from "./Flex";
import { IoHomeOutline } from "react-icons/io5";
import useFormNavigation from "./Navigation"; // Import the custom hook
import "./Waste.css";

const WastePage = () => {
  // Questions data
  const questionsData = [
    {
      id: "waste-details",
      question: "How many bags of trash do you produce weekly?",
      type: "number",
      placeholder: "(no. of bags)",
    },
    {
      id: "recycle",
      question:
        "How do you typically dispose of your household organic waste? (Composting, regular trash, municipal organic waste collection, or other methods?)",
      type: "radio",
      options: ["Composting", "Regular Trash", "Municipal Collection", "Other"],
    },
    {
      id: "compost",
      question: "Do you compost organic waste? If yes, how often?",
      type: "radio",
      options: ["Once a week", "Twice a week", "Monthly", "Rarely"],
    },
  ];

  // Use the custom hook for navigation
  const { currentQuestion, nextQuestion, prevQuestion } = useFormNavigation(
    questionsData,
    "/Diet", // Next page route after finishing the questions
    "/Home-Page" // Previous page route when going back
  );

  // Optional: Home button handler to navigate to the home page
  const handleHome = () => {
    prevQuestion(); // You can navigate back using the previous question logic
  };

  return (
    <div>
      <IoHomeOutline className="waste-home-btn" onClick={handleHome} />
      <FlexboxComponent
        title="Waste-management Information"
        questions={questionsData}
        nextPath="/diet"
        prevPath="/Transport"
      />
    </div>
  );
};

export default WastePage;
