import React from "react";
import FlexboxComponent from "./Flex";
import { useNavigate } from "react-router-dom";
import { IoHomeOutline } from "react-icons/io5";
import "./Transport.css";

// Initialize navigate

const Transport = () => {
  const navigate = useNavigate();
  const handlePrevious = () => {
    navigate("/Home-Page"); // Now you can use navigate properly
  };
  const questionsData = [
    {
      id: "Transportation details",
      question: "How many vehicles are in your household?",
      type: "range", // Changed to "number" for numeric input
      min: "1",
      max: "10",
      placeholder: "No of vehicles",
    },
    {
      id: "Car/Bike details",
      question: "How many km do you drive per week?",
      type: "number", // Changed to "number" for numeric input
      placeholder: "(in km)",
    },
    {
      id: "Public Transport",
      question:
        "How often do you use public transportation (bus, train, etc.)?",
      type: "number",
      placeholder: "per week",
    },
    {
      id: "Air-Travel",
      question: "Do you travel in flights",
      type: "radio",
      options: ["Yes", "No"],
    },
    {
      id: "Air-Travel1",
      question: "How many flights do you take per year",
      type: "number",
      placeholder: "(No of trips)",
    },
  ];

  return (
    <div>
      <IoHomeOutline
        className="transport-home-btn"
        onClick={handlePrevious}
      ></IoHomeOutline>
      <FlexboxComponent
        title="Transportation Information"
        questions={questionsData}
        nextPath="/Waste"
        prevPath="/House"
      />
    </div>
  );
};

export default Transport;
