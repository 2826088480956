import React from "react";
import FlexboxComponent from "./Flex";
import { IoHomeOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import useFormNavigation from "./Navigation"; // Import the custom hook
import "./Diet.css";

const DietPage = () => {
  const navigate = useNavigate();
  const handlePrevious = () => {
    navigate("/Home-Page"); // Now you can use navigate properly
  };
  // Questions data for the Diet section
  const questionsData = [
    {
      id: "meat-consumption",
      question: "What is your annual consumption of meat?",
      type: "number",
      placeholder: "(in kg)",
    },
    {
      id: "dairy-consumption",
      question: "How much dairy do you consume each year?",
      type: "number",
      placeholder: "(in liters)",
    },
    {
      id: "plant-based-consumption",
      question: "What is your annual consumption of plant-based foods?",
      type: "number",
      placeholder: "(in kg)",
    },
    {
      id: "eating-out",
      question:
        "How often do you eat out, and what types of meals do you typically order (meat-based, vegetarian, vegan)?",
      type: "radio",
      options: ["Meat-based", "Vegetarian", "Vegan"],
    },
    {
      id: "home-grown-food",
      question:
        "Do you grow any of your own food? If so, how much do you produce annually?",
      type: "number",
      placeholder: "(in kg)",
    },
  ];

  // Use the custom hook for navigation
  const { currentQuestion, nextQuestion, prevQuestion } = useFormNavigation(
    questionsData,
    "/Goods", // Next page route after finishing the questions
    "/Waste" // Previous page route when going back
  );

  // Optional: Home button handler to navigate to the home page
  const handleHome = () => {
    prevQuestion(); // You can navigate back using the previous question logic
  };

  return (
    <div>
      <IoHomeOutline className="diet-home-btn" onClick={handlePrevious} />
      <FlexboxComponent
        title="Diet Information"
        questions={questionsData}
        nextPath="/Goods"
        prevPath="/Waste"
      />
    </div>
  );
};

export default DietPage;
