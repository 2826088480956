import React from "react";
import FlexboxComponent from "./Flex";
import { IoHomeOutline } from "react-icons/io5";
import useFormNavigation from "./Navigation"; // Import the custom hook
import "./Goods.css";

const GoodsPage = () => {
  // Questions data for the Goods section
  const questionsData = [
    {
      id: "clothing-items",
      question: "How many clothing items do you purchase each year?",
      type: "number",
      placeholder: "(no. of items)",
    },
    {
      id: "electronic-devices",
      question: "How many electronic devices do you buy annually?",
      type: "number",
      placeholder: "(no. of devices)",
    },
    {
      id: "furniture-pieces",
      question: "How many pieces of furniture do you acquire each year?",
      type: "number",
      placeholder: "(no. of pieces)",
    },
    {
      id: "household-expenditure",
      question:
        "What is your annual expenditure on household goods (e.g., cleaning supplies, kitchenware)?",
      type: "number",
      placeholder: "(in USD or your currency)",
    },
    {
      id: "new-vs-secondhand",
      question:
        "How often do you buy new items versus second-hand or refurbished products?",
      type: "radio",
      options: [
        "Always New",
        "Mostly New",
        "Mostly Second-hand",
        "Always Second-hand",
      ],
    },
  ];

  // Use the custom hook for navigation
  const { currentQuestion, nextQuestion, prevQuestion } = useFormNavigation(
    questionsData,
    "/summary", // Next page route after finishing the questions
    "/Diet" // Previous page route when going back
  );

  // Optional: Home button handler to navigate to the home page
  const handleHome = () => {
    prevQuestion(); // You can navigate back using the previous question logic
  };

  return (
    <div>
      <IoHomeOutline className="goods-home-btn" onClick={handleHome} />
      <FlexboxComponent
        title="Goods Consumption Information"
        questions={questionsData}
        nextPath="/Water"
        prevPath="/Diet"
      />
    </div>
  );
};

export default GoodsPage;
