import { useNavigate } from "react-router-dom"; // Import useNavigate
import {
  Leaf,
  Info,
  BarChart,
  ArrowRight,
  Home,
  Car,
  Utensils,
} from "lucide-react";
import "./HomePage.css";

export default function HomePage() {
  const navigate = useNavigate(); // Initialize useNavigate

  const handleNavigate = (path) => {
    navigate(path); // Function to navigate to different paths
  };
  const handleNext = () => {
    navigate("/House");
  };

  return (
    <div className="home-page-flex flex-col min-h-screen">
      <header className="home-page-header">
        <div className="home-page-logo" onClick={() => handleNavigate("/")}>
          <Leaf className="home-page-logo-icon" />
          <span className="home-page-logo-text">kyc.earth</span>
        </div>
        <nav className="home-page-nav">
          <div
            className="home-page-nav-link1"
            onClick={() => handleNavigate("#about")}
          >
            About
          </div>
          <div
            className="home-page-nav-link2"
            onClick={() => handleNavigate("#contact")}
          >
            Contact
          </div>
        </nav>
      </header>
      <main className="home-page-flex-1">
        <section className="home-page-hero-section">
          <div className="home-page-container">
            <div className="home-page-hero-content">
              <div className="home-page-hero-text">
                <h1 className="home-page-hero-title">
                  Understand Your Environmental Impact
                </h1>
                <p className="home-page-hero-description">
                  Discover your carbon footprint and learn how to reduce your
                  impact on the planet.
                </p>
              </div>
              <button className="home-page-button1" onClick={handleNext}>
                Calculate Now
              </button>
              <button
                className="home-page-button2"
                onClick={() => handleNavigate("#about")}
              >
                Learn More
              </button>
            </div>
          </div>
        </section>
        <section id="about" className="home-page-about-section">
          <div className="home-page-container">
            <h2 className="home-page-about-title">
              Why Carbon Footprinting Matters
            </h2>
            <div className="home-page-grid sm:grid-cols-3 gap-10">
              <div className="home-page-card">
                <div className="home-page-card-header">
                  <Info className="home-page-card-icon" />
                  <h3 className="home-page-card-title">
                    What is a Carbon Footprint?
                  </h3>
                </div>
                <div className="home-page-card-content">
                  <p>
                    A carbon footprint is the total amount of greenhouse gases
                    produced by our daily activities, expressed as equivalent
                    tons of carbon dioxide (CO2).
                  </p>
                </div>
              </div>
              <div className="home-page-card">
                <div className="home-page-card-header">
                  <BarChart className="home-page-card-icon" />
                  <h3 className="home-page-card-title">Measure Your Impact</h3>
                </div>
                <div className="home-page-card-content">
                  <p>
                    By calculating your carbon footprint, you can understand how
                    your lifestyle choices affect the environment and identify
                    areas for improvement.
                  </p>
                </div>
              </div>
              <div className="home-page-card">
                <div className="home-page-card-header">
                  <Leaf className="home-page-card-icon" />
                  <h3 className="home-page-card-title">Take Action</h3>
                </div>
                <div className="home-page-card-content">
                  <p>
                    Once you know your carbon footprint, you can take steps to
                    reduce it, contributing to the global effort to combat
                    climate change.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="calculator" className="home-page-calculator-section">
          <div className="home-page-container">
            <div className="home-page-calculator-content">
              <h2 className="home-page-calculator-title">
                Calculate Your Footprint
              </h2>
              <p className="home-page-calculator-description">
                Our easy-to-use calculator helps you estimate your carbon
                footprint based on your lifestyle.
              </p>
              <div className="home-page-grid">
                <div className="home-page-card">
                  <div className="home-page-card-header">
                    <Home className="home-page-card-icon" />
                    <h3 className="home-page-card-title">Home Energy</h3>
                  </div>
                  <div className="home-page-card-content">
                    <p>
                      Assess your household energy consumption and its
                      environmental impact.
                    </p>
                  </div>
                </div>
                <div className="home-page-card">
                  <div className="home-page-card-header">
                    <Car className="home-page-card-icon" />
                    <h3 className="home-page-card-title">Transportation</h3>
                  </div>
                  <div className="home-page-card-content">
                    <p>
                      Evaluate the carbon emissions from your daily commute and
                      travel habits.
                    </p>
                  </div>
                </div>
                <div className="home-page-card">
                  <div className="home-page-card-header">
                    <Utensils className="home-page-card-icon" />
                    <h3 className="home-page-card-title">Diet & Lifestyle</h3>
                  </div>
                  <div className="home-page-card-content">
                    <p>
                      Understand how your food choices and lifestyle affect your
                      carbon footprint.
                    </p>
                  </div>
                </div>
              </div>
              <button
                className="home-page-calculate-button"
                onClick={handleNext}
              >
                Start Calculation
                <ArrowRight className="arrow-icon" />
              </button>
            </div>
          </div>
        </section>
        <section id="contact" className="home-page-contact-section">
          <div className="home-page-container">
            <div className="home-page-contact-content">
              <h2 className="home-page-contact-title">Get In Touch</h2>
              <p className="home-page-contact-description">
                Have questions about carbon footprinting or need help reducing
                your impact? We're here to help.
              </p>
              <div
                className="home-page-button-contact-button"
                onClick={() => handleNavigate("#contact")}
              >
                Contact Us
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer className="home-page-footer">
        <p className="home-page-footer-text">
          © 2024 EcoTrack Carbon Footprint Calculator. All rights reserved.
        </p>
        <nav className="home-page-footer-nav">
          <div
            className="home-page-footer-link"
            onClick={() => handleNavigate("#terms")}
          >
            Terms of Service
          </div>
          <div
            className="home-page-footer-link"
            onClick={() => handleNavigate("#privacy")}
          >
            Privacy Policy
          </div>
          <div
            className="home-page-footer-link"
            onClick={() => handleNavigate("#cookies")}
          >
            Cookie Policy
          </div>
        </nav>
      </footer>
    </div>
  );
}
